import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
	createAssignment,
	getStaff,
	updateAssignment,
	deleteAssignment,
} from "../../app/api";
import { Staff, StaffAssignment, transformFirebaseListToArray } from "../../app/models";
import { IconTextButton } from "../../components/ui/IconTextButton/IconTextButton";
import './TableModal.scss';

const times = [
	{ value: "10:00 am", label: "10:00 am" },
	{ value: "10:30 am", label: "10:30 am" },
	{ value: "11:00 am", label: "11:00 am" },
	{ value: "11:30 am", label: "11:30 am" },
	{ value: "12:00 pm", label: "12:00 pm" },
	{ value: "12:30 pm", label: "12:30 pm" },
	{ value: "1:00 pm", label: "1:00 pm" },
	{ value: "1:30 pm", label: "1:30 pm" },
	{ value: "2:00 pm", label: "2:00 pm" },
	{ value: "2:30 pm", label: "2:30 pm" },
	{ value: "3:00 pm", label: "3:00 pm" },
	{ value: "3:30 pm", label: "3:30 pm" },
	{ value: "4:00 pm", label: "4:00 pm" },
	{ value: "4:30 pm", label: "4:30 pm" },
	{ value: "5:00 pm", label: "5:00 pm" },
	{ value: "5:30 pm", label: "5:30 pm" },
	{ value: "6:00 pm", label: "6:00 pm" },
	{ value: "6:30 pm", label: "6:30 pm" },
	{ value: "7:00 pm", label: "7:00 pm" },
	{ value: "7:30 pm", label: "7:30 pm" },
	{ value: "8:00 pm", label: "8:00 pm" },
	{ value: "8:30 pm", label: "8:30 pm" },
	{ value: "9:00 pm", label: "9:00 pm" },
	{ value: "9:30 pm", label: "9:30 pm" },
	{ value: "10:00 pm", label: "10:00 pm" },
];

interface TableModalProps {
	tableNumber?: string;
	staff?: Array<Staff>;
	data?: StaffAssignment;
	onSaveSuccess?: () => void;
}

function TableModal({
	data,
	onSaveSuccess
}: TableModalProps) {
	const [from, setFrom] = useState<string | undefined>(data?.from);
	const [to, setTo] = useState<string | undefined>(data?.to);
	const [staffSelected, setStaff] = useState(data?.staffId);
	const [recordId, setRecordId] = useState(data?.key);

	const [staffList, setStaffList] = useState<Array<Staff>>();
	const [staffOptions, setStaffOptions] = useState<Array<any>>();

	const dd = new Date(data?.date || '');
	const m = dd.toLocaleString("en-us", { month: "short" });
	const d = `${m} ${dd.getDate()}, ${dd.getFullYear()}`;

	const [loading, setLoading] = useState(false);

	const getStaffOptions = () => {
		const b = staffList?.map(a => {
			return { value: a.key, label: a.name};
		});
		setStaffOptions(b);
	};

	const upsertStaffTableAssignment = async () => {

		if(loading) return;

		setLoading(true);

		if (!from || !to || !staffSelected) {
			return;
		}

		const d: StaffAssignment = {
			date: data?.date,
			from: from,
			to: to,
			staffId: staffSelected,
			name: staffList?.find((a) => a.key === staffSelected)?.name,
			tableNumber: data?.tableNumber,
		};

		if(recordId) {
			const res = await updateAssignment(d, recordId);
			setLoading(false);
			if (res)
				onSaveSuccess && onSaveSuccess();
		} else {
			const res = await createAssignment(d);
			setLoading(false);
			if (res)
				onSaveSuccess && onSaveSuccess();
		}
	};

	const deleteStaffTableAssignment = async () => {
		if(recordId) {
			const res = await deleteAssignment(recordId);
			if(res) {
				onSaveSuccess && onSaveSuccess();
			}
		}
	}

	useEffect(() => {
		init();
	},[]);

	useEffect(() => {
		getStaffOptions();
	}, [staffList]);

	const init = async () => {
		const staff = await getStaff();
		setStaffList(transformFirebaseListToArray(staff, true));
	}

	return (
		<div className="table-modal">
			<div className="table-number">
				<div>Assign Staff to Table {data?.tableNumber}</div>
				<div className="date">for {d}</div>
			</div>

			<div className="stafflist input-group">
				<div className="label">Staff:</div>
				<div className="input-container">
					<Select
						value={staffOptions?.find(
							(a) => a.value === staffSelected
						)}
						options={staffOptions}
						onChange={(v) => setStaff(v?.value)}
					/>
				</div>
			</div>
			<div className="from-time input-group">
				<div className="label">From:</div>
				<div className="input-container">
					<Select
						value={times.find((a) => a.value === from)}
						options={times}
						onChange={(v) => setFrom(v?.value)}
					/>
				</div>
			</div>
			<div className="to-time input-group">
				<div className="label">To:</div>
				<div className="input-container">
					<Select
						value={times.find((a) => a.value === to)}
						options={times}
						onChange={(v) => setTo(v?.value)}
					/>
				</div>
			</div>

			<div className="submit-control">
				{from && to && staffSelected && recordId && <IconTextButton
					onClick={deleteStaffTableAssignment}
					className="submit"
					text="Delete"
					icon={faTrash}
				/>}
				<IconTextButton
					onClick={upsertStaffTableAssignment}
					className="submit"
					text={recordId ? "Update" : "Save"}
					icon={faCheck}
				/>
			</div>
		</div>
	);
}

export default TableModal;
