import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './IconTextButton.scss';

export const IconTextButton = ({icon, text, className, noPadding, noBorder, onClick}: any) => {
	return (
		<button onClick={onClick} className={`icon-text-button ${className ? className : ''} ${noPadding ? 'no-padding' : ''} ${noBorder ? 'no-border' : ''}`}>
			<FontAwesomeIcon className="icon" icon={icon} />
			<span>{text}</span>
		</button>
	)
}
