import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { generateEmptyMenuPage, MenuPage } from "../../../../app/models";
import { Appendable } from "../../../ui/Appendable/Appendable";
import { IconTextButton } from "../../../ui/IconTextButton/IconTextButton";
import { TextInputWithState } from "../../../ui/TextInputWithState/TextInputWithState";
import './AddMenuPage.scss';

interface AddMenuPageProps {
	item?: any;
	label?: string;
	onSave?: (a: MenuPage) => void;
}

export function AddMenuPage({ item, label, onSave }: AddMenuPageProps) {
	const [_item, setItem] = useState(generateEmptyMenuPage());

	const handleAddColumns = () => {
		const newitem = {
			..._item,
			Columns: ["", ""],
		} as MenuPage;
		setItem(newitem);
	};

	const handleRemoveColumns = () => {
		const newitem = {
			..._item,
			Columns: undefined,
		} as MenuPage;
		setItem(newitem);
	};

	const handleSetItemName = (data: any) => {
		const newitem = {
			..._item,
			Title: data
		} as MenuPage;
		setItem(newitem);
	}

	const handleOnSave = () => {
		onSave && onSave(_item);
	}

	return (
		<div className="add-menu-page">
			<label>Add New Menu Section</label>
			<div className="">
				<TextInputWithState
					onChange={handleSetItemName}
					item={_item.Title}
					placeholder="Section Name"
				/>
			</div>
			<div className="columns-control">
				<Appendable
					key={_item.Id?.toString()}
					onExpand={handleAddColumns}
					onRemove={handleRemoveColumns}
					addLabel="Add Price Columns"
					mainLabel="Price Columns"
					values={_item?.Columns}
				/>
			</div>

			<div className="submit-section">
				<IconTextButton
					onClick={handleOnSave}
					className="red"
					icon={faCheck}
					text="Save Menu Section"
				/>
			</div>
		</div>
	);
}
