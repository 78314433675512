import { useEffect, useState } from "react";
import { Item } from "../../../app/models";
import "./ItemDisplay.scss";
import "../Global.scss";
import { SubItemDisplay } from "./SubItemDisplay";
import { IconTextButton } from "../../ui/IconTextButton/IconTextButton";
import { faEye, faEyeSlash, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalContainer from "../../ui/Modal/ModalContainer";
import { EditMenuPage } from "../AdminModes/MenuPage/EditMenuPage";
import Modal from "../../ui/Modal/Modal";
import { EditGenericItem } from "../AdminModes/GenericItem/EditGenericItem";
import { IconButton } from "../../ui/IconButton/IconButton";

interface ItemProps {
	item: Item;
	columns?: Array<string>;
	onSave?: (data: Item) => void;
}

export function ItemDisplay({ item, columns, onSave }: ItemProps) {
	const [showModal, setShowModal] = useState(false);
	const [_item, setItem] = useState(item);

	useEffect(() => {
		setItem(item);
	}, [item]);

	const multiprice = [_item.Price1, _item.Price2];

	const closeModal = () => {
		setShowModal(false)
	}

	const showEditModal = () => {
		setShowModal(true);
	};

	const handleSaveEdit = (data: any) => {
		onSave && onSave(data);
		setShowModal(false);
	}

	const enableItem = () => _setShow(true);
	const disableItem = () => _setShow(false);

	const _setShow = (state: boolean) => {
		const newItem = {
			..._item,
			Show: state,
		} as Item;
		handleSaveEdit(newItem);
		// setItem(newItem);
		// onSave && onSave(item);
	}

	return (
		<div
			className={`item generic display ${!_item.Show ? "disabled" : ""}`}
		>
			{showModal && (
				<ModalContainer>
					<Modal onClick={closeModal}>
						<EditGenericItem
							item={item}
							columns={columns}
							onSave={handleSaveEdit}
						/>
					</Modal>
				</ModalContainer>
			)}
			<div className="item-header">
				<div className="left">
					<div className="val">{_item.Number}</div>
					<div className="val">{_item.Name}</div>
				</div>
				<div className="right">
					<IconButton icon={faPen} onClick={showEditModal} />
					{_item.Show === true ? (
						<IconButton icon={faEyeSlash} onClick={disableItem} />
					) : (
						<IconButton icon={faEye} onClick={enableItem} />
					)}
				</div>
			</div>

			<div className="item-body">
				{(!columns || !columns.length) && (
					<div className="row">
						<label>Price: </label>
						<div className="val">
							{_item.Price ? _item.Price : "-"}
						</div>
					</div>
				)}

				{columns && columns.length && (
					<div className="flex-group">
						{columns.map((a, idx) => (
							<div className="row">
								<label>{a} Price: </label>
								<div className="val">{multiprice[idx]}</div>
							</div>
						))}
					</div>
				)}

				{(_item.Icon || _item.Icon2) && (
					<div className="row">
						<label>Item Icon(s): </label>
						<div className="val">
							{_item.Icon} {_item.Icon2}
						</div>
					</div>
				)}

				{_item.Items?.length && (
					<SubItemDisplay
						key={_item.Id?.toString()}
						items={_item.Items}
					/>
				)}

				{_item.Description && (
					<div className="row">
						<label>Item Description: </label>
						<div className="val">{_item.Description}</div>
					</div>
				)}

				{_item.Extracharge && _item.Extracharge.length && (
					<div className="row item-extracharge">
						<label>Extra Charge Text:</label>
						<div className="val">{_item.Extracharge}</div>
					</div>
				)}

				{_item.ExtraExtraSub && (
					<div className="row item-extrasub">
						<label>Extra Sub Text:</label>
						<div className="val">{_item.ExtraExtraSub}</div>
					</div>
				)}
			</div>
		</div>
	);
}
