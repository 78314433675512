import { faCheck, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { saveData } from "../../app/api";
import { ActionMode, MenuPage } from "../../app/models";
import { IconTextButton } from "../ui/IconTextButton/IconTextButton";
import Modal from "../ui/Modal/Modal";
import ModalContainer from "../ui/Modal/ModalContainer";
import { Notifications, NotificationType } from "../ui/Notifications/Notifications";
import './AdminBase.scss';
import { AddMenuPage } from "./AdminModes/MenuPage/AddMenuPage";
import { Help } from "./Help/Help";
import MenuAdmin from "./MenuAdmin/MenuAdmin";

interface AdminBaseProps {
	pages: Array<MenuPage>;
	onSave?: () => void;
}

function AdminBase({ pages, onSave }: AdminBaseProps) {

	const [menupages, setMenuPages] = useState(pages);
	const [showModal, setShowModal] = useState(false);
	const notificationsRef = useRef(null);
	const [mode, setMode] = useState(ActionMode.ADD);

	const [alert, setAlert] = useState({message: '', type: NotificationType.SUCCESS});

	const _cache = JSON.stringify(pages);

	const addNewMenuPage = () => {
		setMode(ActionMode.ADD);
		setShowModal(true);
	}

	const handleOnEdit = (m: MenuPage) => {
		const mp = menupages?.map((a) => {
			if (a.Id === m.Id) {
				return {...m};
			}
			return {...a} as MenuPage;
		});

		setMenuPages(mp);
	}

	const generateAdminPageMenu = () => {
		if (menupages)
			return (
				<ul>
					{menupages.map((a: MenuPage) => (
						<li key={a.Id}>
							<MenuAdmin item={a} onEdit={handleOnEdit} />
						</li>
					))}
				</ul>
			);
	}

	const handleSaveNewMenuPage = (newmenupage: MenuPage) => {
		const m = [...menupages];
		const last = m[m.length - 1];

		m.push({
			...newmenupage,
			Id: last ? Number(last.Id) + 1 : Math.floor(Math.random() * 100)
		});

		setMenuPages(m);
		popBanner();
	}

	const handleSaveAll = async () => {
		const s = JSON.stringify(menupages);
		if(_cache !== s) {
			const res = await saveData(menupages);
			if(res === true) {
				onSave && onSave();
				setAlert({
					message: "Saved Successfully",
					type: NotificationType.SUCCESS,
				});
			}
		} else {
			setAlert({ message: 'No Changes to be Saved', type: NotificationType.ERROR });
		}
	};

	const popBanner = () => {
		setShowModal(false);
	};

	const dismissAlert = () => {
		setAlert({ message: '', type: NotificationType.SUCCESS });
	}

	return (
		<div className="admin-base-container">
			{showModal && (
				<ModalContainer>
					<Modal onClick={popBanner}>
						<AddMenuPage onSave={handleSaveNewMenuPage} />
					</Modal>
				</ModalContainer>
			)}
			<div className="admin-base-submenu">
				<div className="controls">
					{/* <IconTextButton
						text="Add Menu Section"
						icon={faFolderPlus}
						onClick={addNewMenuPage}
					/>
					<Help /> */}
					<IconTextButton icon={faCheck} text="Save All Changes" onClick={handleSaveAll} />
					{
						alert && alert.message &&
						<Notifications type={alert.type} message={alert.message} onDismiss={dismissAlert} />
					}
				</div>
				{menupages && generateAdminPageMenu()}
			</div>
		</div>
	);
}

export default AdminBase;
