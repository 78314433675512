import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.scss";
import logo from "../../static/genji-justlogo.png";

interface HeaderMenuItem {
	id: number;
	title: string;
	path: string;
	active: boolean;
}

const headermenu: Array<HeaderMenuItem> = [
	{
		id: Math.random(),
		title: "Customers",
		path: "/customers",
		active: false,
	},
	{ id: Math.random(), title: "Menu", path: "/menu", active: false },
	// {
	// 	id: Math.random(),
	// 	title: "Company Admin",
	// 	path: "/companydata",
	// 	active: false,
	// },
	// {
	// 	id: Math.random(),
	// 	title: "Settings Admin",
	// 	path: "/uiconfig",
	// 	active: false,
	// },
	{
		id: Math.random(),
		title: "Tables",
		path: "/tables",
		active: false,
	},
];

function Header() {
	const [menu] = useState(headermenu);
	const [activeMenuId, setActiveMenuId] = useState<number>(0);
	let location = useLocation();

	useEffect(() => {
		const match = menu.find(a => a.path === location.pathname);
		if(match) {
			setActiveMenuId(match.id);
		} else {
			setActiveMenuId(0);
		}
	}, [location, menu]);

	return (
		<header>
			<nav className="header-menu">
				<div className="logo-container" onClick={() => setActiveMenuId(0)}>
					<Link to={"/"}>
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<div className="menu-list">
					<ul>
						{menu.map((menuitem) => {
							return (
								<li
									className={`menu-item ${
										activeMenuId === menuitem.id
											? "active"
											: ""
									}`}
									key={menuitem.id}
								>
									<Link to={menuitem.path}>
										{menuitem.title}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</nav>

			{/* <div className="ctrl">
				<button className="signout">Sign out</button>
			</div> */}
		</header>
	);
}

export default Header;
