import { useEffect, useState } from "react";
import { getData } from "../../app/api";
import { MenuPage, MenuPagesResponse, sortAlphaNum } from "../../app/models";
import Admin from "../../components/admin/Admin";
import AdminBase from "../../components/admin/AdminBase";

function Menu() {
	const [menupages, setMenuPages] = useState<Array<MenuPage> | undefined>(undefined);

	const _getData = async () => {
		const a = await getData();
		console.log(a);

		a.MenuPage?.sort((v1, v2) => v1.Id != null && v2.Id != null && v1.Id > v2.Id ? 1 : -1)

		a.MenuPage?.forEach(b => {
			b.Items?.sort((c, d) => sortAlphaNum(c.Number, d.Number));

			b.Category?.forEach((e) =>
				e.Items?.sort((c, d) => sortAlphaNum(c.Number, d.Number))
			);
		})

		setMenuPages(a.MenuPage);
	}

	useEffect(() => {
		_getData();
	}, []);

	const repullData = async () => {
		await _getData();
	}

	return (
		<div className="page__menu">
			<h1 className="header">Manage Menu</h1>

			{menupages && (
				<AdminBase pages={menupages} onSave={repullData}></AdminBase>
			)}
		</div>
	);

}

export default Menu;
