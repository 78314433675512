import { useState } from "react";
import './GenericItem.scss';
import '../../Global.scss';
import { SubItems } from "../../Item/SubItems";
import { Item } from "../../../../app/models";
import { IconTextButton } from "../../../ui/IconTextButton/IconTextButton";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface EditGenericItemProps {
	item: Item;
	columns?: Array<string>;
	onSave?: (data: Item) => void;
}

export function EditGenericItem({ item, columns, onSave }: EditGenericItemProps) {
	const [itemName, setItemName] = useState(item.Name);
	const [itemDesc, setItemDesc] = useState(item.Description);
	const [number, setNumber] = useState(item.Number);
	const [extracharge, setExtracharge] = useState(item.Extracharge);
	const [icon, setIcon] = useState(item.Icon);
	const [icon2, setIcon2] = useState(item.Icon2);
	const [extrasub, setExtraSub] = useState(item.ExtraExtraSub);
	const [price, setPrice] = useState(item.Price);
	const [price1, setPrice1] = useState(item.Price1);
	const [price2, setPrice2] = useState(item.Price2);

	const handleItemNameChange = (data: any) => {
		setItemName(data.target.value);
	};
	const handleItemDescChange = (data: any) => {
		setItemDesc(data.target.value);
	};
	const handleItemPriceChange = (data: any) => {
		setPrice(data.target.value);
	};
	const handleItemPrice1Change = (data: any) => {
		setPrice1(data.target.value);
	};
	const handleItemPrice2Change = (data: any) => {
		setPrice2(data.target.value);
	};
	const handleItemNumberChange = (data: any) => {
		setNumber(data.target.value);
	};
	const handleItemExtrachargeChange = (data: any) => {
		setExtracharge(data.target.value);
	};
	const handleItemIconChange = (data: any) => {
		setIcon(data.target.value);
	};
	const handleItemIcon2Change = (data: any) => {
		setIcon2(data.target.value);
	};
	const handleItemExtraSubChange = (data: any) => {
		setExtraSub(data.target.value);
	};

	const handleSaveEdit = () => {
		onSave &&
			onSave({
				Id: item.Id,
				Name: itemName,
				Number: number,
				Price: price,
				Price1: price1,
				Price2: price2,
				Description: itemDesc,
				Icon: icon,
				Icon2: icon2,
				Extracharge: extracharge,
				ExtraExtraSub: extrasub,
				Show: item.Show
			} as Item);
	}

	const multipricehandlers = [handleItemPrice1Change, handleItemPrice2Change];
	const multiprice = [price1, price2];

	return (
		<>
			<div className="item generic">
				<div className="form-title">
					<label>Edit Item</label>
				</div>
				<div className="flex-form-group">
					<div className="form-group item-number-group">
						<label>Item #</label>
						<input
							type="text"
							className="form-text item-number"
							placeholder="X#"
							value={number}
							onChange={handleItemNumberChange}
						/>
					</div>
					<div className="form-group item-name-group">
						<label>Item Name</label>
						<input
							type="text"
							className="form-text"
							placeholder="Item Name"
							value={itemName}
							onChange={handleItemNameChange}
						/>
					</div>

					{(!columns || !columns.length) && (
						<div className="form-group item-price-group">
							<label>Item Price</label>
							<input
								type="text"
								className="form-text item-price"
								placeholder="$$$"
								value={price}
								onChange={handleItemPriceChange}
							/>
						</div>
					)}
				</div>

				{columns &&
					columns.length &&
					columns.map((a, idx) => (
						<div className="flex-form-group" key={a}>
							<div className="form-group">
								<label>{a} Price</label>
								<input
									type="number"
									className="form-text"
									placeholder="$$$"
									value={multiprice[idx]}
									onChange={multipricehandlers[idx]}
								/>
							</div>
						</div>
					))}

				<div className="flex-form-group">
					<div className="form-group">
						<label>Item Icon 1</label>
						<input
							type="text"
							className="form-text item-icon"
							placeholder="Fire / Vegetable / New"
							value={icon}
							onChange={handleItemIconChange}
						/>
					</div>
					<div className="form-group">
						<label>Item Icon 2</label>
						<input
							type="text"
							className="form-text"
							placeholder="Fire / Vegetable / New"
							value={icon2}
							onChange={handleItemIcon2Change}
						/>
					</div>
				</div>

				{item.Items?.length && (
					<SubItems key={item.Id?.toString()} items={item.Items} />
				)}

				<div className="form-group">
					<label>Item Description</label>
					<textarea
						className="form-textarea"
						placeholder="Item Description"
						value={itemDesc}
						onChange={handleItemDescChange}
					></textarea>
				</div>
				<div className="form-group item-extracharge">
					<label>Extra Charge</label>
					<input
						type="text"
						className="form-text"
						placeholder="Item Extra Charge"
						value={extracharge}
						onChange={handleItemExtrachargeChange}
					/>
				</div>
				<div className="form-group item-extrasub">
					<label>Extra Sub Text</label>
					<input
						type="text"
						className="form-text"
						placeholder="Item Sub Text"
						value={extrasub}
						onChange={handleItemExtraSubChange}
					/>
				</div>
			</div>

			<div className="submit-controls">
				<IconTextButton className="red" icon={faCheck} text="Save Item" onClick={handleSaveEdit} />
			</div>
		</>
	);
}
