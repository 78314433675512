import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { IconButton } from "../IconButton/IconButton";
import './Notifications.scss';

export enum NotificationType {
	ERROR,
	SUCCESS
}

interface NotificationsProps {
	type: NotificationType;
	message: string;
	onDismiss?: () => void;
}

export function Notifications({ type, message, onDismiss }: NotificationsProps) {
	useEffect(() => {
		setTimeout(() => {
			dismiss();
		}, 3000);
	});

	const dismiss = () => {
		onDismiss && onDismiss();
	};

	return (
		<div
			className={`notification-container ${
				type === NotificationType.ERROR ? "error" : "success"
			}`}
		>
			<div className="notification-content">
				<div className="notification-message">{message}</div>
				<div className="notification-header">
					<IconButton
						className="close-noti"
						noPadding
						noBorder
						icon={faTimes}
						onClick={dismiss}
					/>
				</div>
			</div>
		</div>
	);
}
