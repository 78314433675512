import { faCalendarAlt, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { getStaff, getStaffTableAssignments, getTables } from "../../app/api";
import { Staff, StaffAssignment, transformFirebaseListToArray } from "../../app/models";
import { IconButton } from "../../components/ui/IconButton/IconButton";
import { IconTextButton } from "../../components/ui/IconTextButton/IconTextButton";
import Modal from "../../components/ui/Modal/Modal";
import ModalContainer from "../../components/ui/Modal/ModalContainer";
import TableModal from "./TableModal";
import './Tables.scss';

export interface Table {
	tableNumber?: string;
	active?: boolean;
}

function Tables() {

	const today = new Date();

	const [date, setChangeDate] = useState(today);
	const [tables, setTables] = useState<Array<Table>>([]);
	const [staff, setStaff] = useState<Array<Staff>>([]);
	const [staffAssignments, setStaffTableAssignments] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedTableNumber, setSelectedTableNumber] = useState<string>();

	const [selectedStaffAssignment, setSelectedStaffAssignment] = useState<StaffAssignment | undefined>();

	const handleShowLast30 = () => {

	}

	const handleOnChange = (v) => {
		console.log(v);
		setChangeDate(v);
	}

	const initTableData = async () => {
		const tables = await getTables();
		const sa = await getStaffTableAssignments(date);

		console.log(tables, staff);

		setTables(tables);
		setStaffTableAssignments(sa);
	}

	const openAssignmentModal = (tableNumber: string | undefined) => {
		setSelectedTableNumber(tableNumber);
		setSelectedStaffAssignment({
			tableNumber,
			date
		});
		setShowModal(true);
	}

	const openExistingAssignmentModal = (e: any, a: StaffAssignment) => {
		e.preventDefault();
		e.stopPropagation();
		setSelectedStaffAssignment(a);
	}

	const closeModal = () => {
		setShowModal(false);
	}

	const closeModalAndRefresh = () => {
		initTableData();
		closeModal();
	}

	const getStaffAssigned = (tableNumber) => {
		const st: Array<StaffAssignment> = transformFirebaseListToArray(staffAssignments, true);
		const filtered = st.filter(v => v.tableNumber === tableNumber);
		return filtered.map((a: StaffAssignment) => (
			<button
				onClick={(e) => openExistingAssignmentModal(e, a)}
				className="s-ass"
			>
				<span className="s-ass-name">{a.name}</span>: {a.from} - {a.to}
			</button>
		));

	}

	const renderTables = () => {
		const st = transformFirebaseListToArray(tables, true);
		return st.map((a) => (
			<div className="table" key={a.key}>
				<div className="table-number">
					<div>Table {a.tableNumber}</div>
					<IconTextButton
						className="add-assignment"
						text="Add"
						icon={faPlus}
						noBorder
						onClick={() => openAssignmentModal(a.tableNumber)}
					></IconTextButton>
				</div>
				<div className="assigned-staff">
					{getStaffAssigned(a.tableNumber)}
				</div>
			</div>
		));
	}

	const closeTableAssignment = () => {
		setShowModal(false);
	}

	useEffect(() => {
		initTableData();
	}, [date]);

	useEffect(() => {
		console.log(selectedStaffAssignment);
		if(selectedStaffAssignment)
			setShowModal(true);
	}, [selectedStaffAssignment]);

	return (
		<div className="page__tables">
			{showModal && (
				<ModalContainer>
					<Modal onClick={closeTableAssignment}>
						<TableModal
							data={selectedStaffAssignment}
							onSaveSuccess={closeModalAndRefresh}
						/>
					</Modal>
				</ModalContainer>
			)}

			<h1 className="header">Table Assignments</h1>

			{/* <div className="show-past">
				<IconTextButton
					onClick={handleShowLast30}
					text="Show assignments from last 30 days"
					icon={faCalendarAlt}
				/>
			</div> */}

			<div className="control-header">
				<DatePicker
					onChange={v => handleOnChange(v) }
					value={date}
					format="MMM dd yyyy"
					minDate={today}
				/>
			</div>

			<div className="tables-list">{renderTables()}</div>
		</div>
	);
}

export default Tables;
