import { useEffect, useState } from "react";

interface TextInputWithStateProps {
	item?: any;
	label?: any;
	placeholder?: string;
	className?: string;
	onChange?: (text: any) => void;
}

export function TextInputWithState({ item, placeholder = '', label, className, onChange }: TextInputWithStateProps) {
	const [text, setText] = useState(item);

	useEffect(() => {
		setText(item);
	}, [item])

	const handleChange = (data: any) => {
		const newval = data.target.value;
		setText(data.target.value);
		onChange && onChange(newval);
	}

	return (
		<div className={`form-group ${className}`}>
			<label>{label}</label>
			<input
				type="text"
				className="form-text"
				placeholder={placeholder}
				value={text}
				onChange={handleChange}
			/>
		</div>
	);
}
