import { Basic3DCube } from "./Basic3DCube";
import { BasicCircleChart } from "./BasicCircle";
import { BasicLineChart } from "./BasicLine";


function Dashboard() {
	return (
		<div className="page__dashboard">
			<h1 className="header">Dashboard</h1>

			{/* <div className="graphs">
				<div className="c-circle">
					<div className="chartname">Basic SVG Circle </div>
					<BasicCircleChart />
				</div>
				<div className="c-line">
					<div className="chartname">Basic SVG Line Chart</div>
					<BasicLineChart />
				</div>
				<div className="c-cube">
					<div className="chartname">
						Basic WebGL Cube By Data Chart (scroll and drag)
					</div>
					<Basic3DCube />
				</div>
			</div> */}
		</div>
	);
}

export default Dashboard;
