import { useEffect, useState } from "react";
import { MenuPage, ActionMode, Item, Category } from "../../../app/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft, faCheck, faChevronCircleDown, faChevronDown, faChevronUp, faPen, faPlus
} from "@fortawesome/free-solid-svg-icons";
import './MenuAdmin.scss';
import { IconTextButton } from "../../ui/IconTextButton/IconTextButton";
import { IconButton } from "../../ui/IconButton/IconButton";
import { MenuCategory } from "../Category/MenuCategory";
import { GenericItem } from "../Item/GenericItem";
import { TextInputWithState } from "../../ui/TextInputWithState/TextInputWithState";
import { Appendable } from "../../ui/Appendable/Appendable";
import ModalContainer from "../../ui/Modal/ModalContainer";
import Modal from "../../ui/Modal/Modal";
import { EditMenuPage } from "../AdminModes/MenuPage/EditMenuPage";
import { ItemDisplay } from "../Item/ItemDisplay";

interface AdminProps {
	item: MenuPage;
	onEdit?: (data: MenuPage) => void;
}

function MenuAdmin({ item, onEdit }: AdminProps) {
	const [menupage, setMenuPage] = useState(item);
	const [expandSub, setExpandSub] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setMenuPage(item);
	}, [item]);

	const handleExpandCollapse = () => {
		setExpandSub(!expandSub);
	}

	const handleEditClick = () => {
		setShowModal(true);
	}

	const handleSaveEdit = (item: MenuPage) => {

		onEdit && onEdit({...item} as MenuPage);
		closeModal();
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const handleItemSave = (item: Item) => {
		if(menupage) {
			const items = [...(menupage.Items || [])];
			const newitems = items.map(q => {
				if(q.Id === item.Id) {
					return {
						...item
					}
				}
				return q;
			});

			const newitem = { ...menupage };
			newitem.Items = newitems;

			setMenuPage(newitem);
			handleSaveEdit(newitem);
		}
	}

	const handleCategorySave = (cat: Category) => {
		const cats = [...(menupage.Category || [])];
		const newcats = cats.map((q) => {
			if (q.Id === cat.Id) {
				return {
					...cat,
				};
			}
			return q;
		});

		const newmenupage = { ...menupage };
		newmenupage.Category = newcats;
		setMenuPage(newmenupage);
		handleSaveEdit(newmenupage);
	}

	return (
		<div
			className={`menu-admin-container ${!menupage.Show ? "disabled" : ""}`}
		>
			{showModal && (
				<ModalContainer>
					<Modal onClick={closeModal}>
						<EditMenuPage item={menupage} onSave={handleSaveEdit} />
					</Modal>
				</ModalContainer>
			)}
			<div
				className={`menu-admin-header  ${expandSub ? "expanded" : ""}`}
			>
				<div className="menu-admin-header-expand">
					<IconButton
						className="expand-collapse"
						onClick={handleExpandCollapse}
						icon={expandSub ? faChevronUp : faChevronDown}
					/>
				</div>
				<div className="menu-admin-header-content">
					<div className="menu-admin-header-title">
						{menupage.Title}
						<IconTextButton
							onClick={handleEditClick}
							className="small"
							noBorder
							noPadding
							text="Edit Section"
							icon={faPen}
						/>
					</div>
					{menupage?.Columns && (
						<div className="menu-admin-columns">
							<label>Price Columns</label>
							<div className="columns">
								{menupage?.Columns?.map((a, idx) => (
									<div
										className="column"
										key={idx.toString()}
									>
										{a}
									</div>
								))}
							</div>
						</div>
					)}
					{/* <div className="menu-admin-header-controls">
						<IconTextButton icon={faPlus} text="New Item" />
						<IconTextButton icon={faPlus} text="New Subsection" />
					</div> */}
				</div>
			</div>
			<div className="menu-admin-body">
				{menupage?.Extracharge?.length && expandSub && (
					<div className="menu-admin-extra-charges">
						{menupage.Extracharge.map((b, idx) => (
							<TextInputWithState key={idx.toString()} item={b} />
						))}
					</div>
				)}

				{menupage.Items && expandSub && (
					<div className="menu-admin-items">
						{menupage.Items.map((a) => (
							<ItemDisplay
								key={a.Id?.toString()}
								columns={menupage.Columns}
								onSave={handleItemSave}
								item={a}
							/>
						))}
					</div>
				)}

				{menupage.Category &&
					expandSub &&
					menupage.Category.map((a) => (
						<MenuCategory
							key={a.Id?.toString()}
							category={a}
							onSave={handleCategorySave}
						/>
					))}
			</div>
		</div>
	);
}

export default MenuAdmin;
