import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { MenuPage } from "../../../app/models";
import { IconButton } from "../IconButton/IconButton";
import { IconTextButton } from "../IconTextButton/IconTextButton";
import "./Appendable.scss";

interface AppendableProps {
	mainLabel?: string;
	addLabel?: string;
	values?: Array<any>;
	onExpand?: () => void;
	onRemove?: () => void;
	onUpdateValues?: (cols: Array<any>) => void;
}

export function Appendable({
	mainLabel,
	addLabel,
	values,
	onExpand,
	onRemove,
	onUpdateValues,
}: AppendableProps) {
	const [vals, setValues] = useState(values);

	useEffect(() => {
		setValues(values);
	}, [values]);

	const handleValueChange = (data: any, idx: number) => {
		if (vals) {
			const newvals = [...vals];
			newvals[idx] = data.target.value;
			setValues(newvals);

			onUpdateValues && onUpdateValues(newvals);
		}
	};

	const handleRemove = () => {
		onRemove && onRemove();
	};

	const generateItems = () => {
		return (
			<div className="appendable-items">
				<div className="appendable-items-header">
					<label>{mainLabel}</label>
					<IconButton
						icon={faTimes}
						noBorder={true}
						noPadding={true}
						onClick={handleRemove}
					/>
				</div>

				<div className="flex-form-group">
					{vals &&
						vals.length &&
						vals?.map((v, idx) => (
							<div key={idx.toString()} className="form-group">
								<input
									type="text"
									className="form-text item-icon"
									placeholder="Column Name"
									value={v}
									onChange={(a) => handleValueChange(a, idx)}
								/>
							</div>
						))}
				</div>
			</div>
		);
	};

	const generateAppend = () => {
		return (
			<div className="append-control">
				<IconTextButton
					onClick={onExpand}
					text={addLabel}
					className="min"
					noBorder={true}
					noPadding={true}
					icon={faChevronDown}
				/>
			</div>
		);
	};

	return (
		<div className="appendable">
			{vals && vals?.length ? generateItems() : generateAppend()}
		</div>
	);
}
