import { faCalendarAlt, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { getCustomerTables } from "../../app/api";
import { Staff, StaffAssignment, transformFirebaseListToArray } from "../../app/models";
import { IconButton } from "../../components/ui/IconButton/IconButton";
import { IconTextButton } from "../../components/ui/IconTextButton/IconTextButton";
import Modal from "../../components/ui/Modal/Modal";
import ModalContainer from "../../components/ui/Modal/ModalContainer";
import { TextInputWithState } from "../../components/ui/TextInputWithState/TextInputWithState";
import './Customers.scss';
import moment from "moment";

export interface CustomerCT {
	server?: string;
	name?: string;
	phone?: string;
	group?: Array<string>;
	isGroup?: boolean;
	personCount?: number;
	tableNumber?: string;
	timeIn?: string;
	timeOut?: string;
	staffName?: string;
	concatString?: string;
}

function Customers() {

	const today = new Date();

	const [date, setChangeDate] = useState(today);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [customers, setCustomers] = useState<Array<CustomerCT>>([]);
	const [processedCustomers, setProcessedCustomers] = useState<Array<CustomerCT>>([]);
	const [filteredCustomers, setFilteredCustomers] = useState<Array<CustomerCT>>([]);

	const initTableData = async () => {
		const customers = await getCustomerTables();
		setCustomers(customers);

		const at = transformFirebaseListToArray<any, CustomerCT>(
			customers,
			true
		);

		const processed = at.map<CustomerCT>((b) => {
			const a = {
				...b,
				timeIn: b.timeIn
					? moment(b.timeIn).format("MMM D YYYY @ h:mm:ss a")
					: "/",
				timeOut: b.timeOut
					? moment(b.timeOut).format("MMM D YYYY @ h:mm:ss a")
					: "/",
				token: undefined,
				key: undefined,
			};

			a.concatString = Object.values(a).join("%");

			return a;
		});

		setProcessedCustomers(processed);

		setFilteredCustomers(processed)
	}

	const grouped = (list: Array<string> | undefined) => {
		return list?.map((a, idx) => <span key={`${a}${idx}`}>{`${a}${idx !== list.length-1 ? ', ' : ''}`}</span>)
	}

	const renderTables = () => {

		return (
			<table className="customers-table">
				<thead>
					<tr>
						<th>Table Number</th>
						<th>Customer Name</th>
						<th>Phone Number</th>
						<th>Group</th>
						<th>Time in</th>
						<th>Time out</th>
						<th>Server</th>
					</tr>
				</thead>
				<tbody>
					{filteredCustomers.map((a) => (
						<tr className="t-row" key={`${a.name}${a.timeIn}`}>
							<td>{a.tableNumber}</td>
							<td>{a.name}</td>
							<td>{a.phone}</td>
							<td>{grouped(a.group)}</td>
							<td>{a.timeIn}</td>
							<td>{a.timeOut}</td>
							<td>{a.staffName}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	const handleSearch = (query) => {
		if(!query) {
			console.log("before", processedCustomers);
			console.log("!!!!!", query, 'process', processedCustomers);
			setFilteredCustomers([...processedCustomers]);
		} else {
			console.log('before', processedCustomers);
			const res = [...processedCustomers].filter((a) => {
				if (!a) return false;
				if (!a?.concatString) return false;
				return a?.concatString?.toLowerCase().indexOf(query?.toLowerCase()) > -1;
			});
			console.log("!!!!!", query, "process", res);
			setFilteredCustomers(res);
		}
	}

	useEffect(() => {
		initTableData();
	}, [date]);

	return (
		<div className="page__customers">
			<h1 className="header">Contact Tracing</h1>

			<div className="control-header">
				<TextInputWithState
					className="search"
					onChange={handleSearch}
					item={searchQuery}
					placeholder="Search by Name, Phone or Date"
				/>
			</div>

			<div>{renderTables()}</div>

		</div>
	);
}

export default Customers;
