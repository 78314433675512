import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './IconButton.scss';

export const IconButton = ({
	icon,
	text,
	className,
	noPadding,
	noBorder,
	onClick
}: any) => {
	return (
		<button
			onClick={onClick}
			className={`icon-button ${className} ${
				noPadding ? "no-padding" : ""
			} ${noBorder ? "no-border" : ""}`}
		>
			<FontAwesomeIcon className="icon" icon={icon} />
		</button>
	);
};
