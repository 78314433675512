import { useState } from "react";
import { Item, ItemLine } from "../../../app/models";
import './SubItems.scss';
import '../Global.scss';

interface SubItemsProps {
	items: Array<ItemLine>;
}

export function SubItems({ items }: SubItemsProps) {
	const [itemlist, setItemList] = useState(items);

	const handleItemNameChange = (obj: any, id?: string) => {
		setItemList(itemlist.map(a => {
			if (a.Letter === id) {
				a.Name = obj.target.value;
			}
			return a;
		}));
	};
	const handleItemPriceChange = (obj: any, id?: string) => {
		setItemList(
			itemlist.map((a) => {
				if (a.Letter === id) {
					a.Price = obj.target.value;
				}
				return a;
			})
		);
	};
	const handleItemLetterChange = (obj: any, id?: string) => {
		setItemList(
			itemlist.map((a) => {
				if (a.Letter === id) {
					a.Letter = obj.target.value;
				}
				return a;
			})
		);
	};

	return (
		<div className="item-generic-sub">
			<div className="item-generic-sub-title">Sub Items</div>

			{items.map((a) => (
				<div className="item-line-container" key={a.Id?.toString()}>
					<div className="form-group item-line-letter">
						<label>Item Letter</label>
						<input
							type="text"
							className="form-text"
							placeholder="Item Letter"
							value={a?.Letter}
							onChange={(obj) =>
								handleItemLetterChange(obj, a.Letter)
							}
						/>
					</div>
					<div className="form-group item-line-name">
						<label>Item Name</label>
						<input
							type="text"
							className="form-text"
							placeholder="Item Name"
							value={a?.Name}
							onChange={(obj) =>
								handleItemNameChange(obj, a.Letter)
							}
						/>
					</div>
					<div className="form-group item-line-price">
						<label>Item Price</label>
						<input
							type="text"
							className="form-text"
							placeholder="$$$"
							value={a?.Price}
							onChange={(obj) =>
								handleItemPriceChange(obj, a.Letter)
							}
						/>
					</div>
				</div>
			))}
		</div>
	);
}
