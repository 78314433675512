import { useState } from "react";
import { Item, ItemLine } from "../../../app/models";
import './ItemDisplay.scss';
import '../Global.scss';
import { IconTextButton } from "../../ui/IconTextButton/IconTextButton";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../ui/IconButton/IconButton";

interface SubItemsProps {
	items: Array<ItemLine>;
}

export function SubItemDisplay({ items }: SubItemsProps) {

	return (
		<div className="item sub display">
			<div className="item-sub-display-title">Sub Items</div>
			{items &&
				items?.map((i) => (
					<div key={i.Id?.toString()}>
						<div className="item-header">
							<div className="left">
								<div className="val">{i.Letter}</div>
								<div className="val">{i.Name}</div>
							</div>
							{/* <div className="right">
								<IconButton icon={faPen} />
								<IconButton icon={faTrash} />
							</div> */}
						</div>

						{	i.Price &&
							<div className="item-body">
								<div className="row">
									<label>Price: </label>
									<div className="val">{i.Price}</div>
								</div>
							</div>
						}
					</div>
				))}
		</div>
	);
}
