import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Category } from "../../../app/models";
import { IconTextButton } from "../../ui/IconTextButton/IconTextButton";
import { GenericItem } from "../Item/GenericItem";
import { ItemDisplay } from "../Item/ItemDisplay";
import './MenuCategory.scss'

interface CategoryProps {
	category: Category;
	onSave?: (cat: Category) => void;
}

export function MenuCategory({ category, onSave }: CategoryProps) {
	const [categoryName, setCategoryName] = useState(category.Name);
	const [categoryDesc, setCategoryDesc] = useState(category.Description);

	const handleCategorySave = (data: any) => {

	}
	const handleItemSave = (data: any) => {
		const items = [...(category.Items || [])];
		const newitems = items.map((q) => {
			if (q.Id === data.Id) {
				return {
					...data,
				};
			}
			return q;
		});

		const newcat = { ...category };
		newcat.Items = newitems;
		onSave && onSave(newcat);
	}

	return (
		<div className="category">
			<div className="category-header">
				<div className="category-name">
					<div className="">
						<label className="category-label">Category:</label>
						<div className="val">{categoryName}</div>
					</div>
					{categoryDesc && (
						<div className="">
							<label className="category-label">
								Description:
							</label>
							<div className="">{categoryDesc}</div>
						</div>
					)}
				</div>
				{/* <div className="category-controls">
					<IconTextButton text="Edit Category" icon={faPen} />
				</div> */}
			</div>

			{category.Items &&
				category.Items.map((a) => (
					<ItemDisplay
						key={a.Id?.toString()}
						item={a}
						onSave={handleItemSave}
					/>
				))}
		</div>
	);
}
