export interface AdminPage {
	pageId: number;
	pageTitle: string;
}

export interface ItemLine {
	Letter?: string;
	Name?: string;
	Price?: string;
	Id?: number;
	Show?: boolean;
}

export interface Item {
	Number?: string;
	Icon?: string;
	Icon2?: string;
	Name?: string;
	Price?: string;
	Price1?: string;
	Price2?: string;
	Description?: string;
	Extracharge?: string;
	Extracharge1?: string;
	ExtraExtraSub?: string;
	ExtrachargeIcon?: string;
	Items?: Array<ItemLine>;
	Lines?: Array<ItemLine>;
	NameDes?: string;
	Id?: number;
	Show?: boolean;
}
export interface MenuCategory {
	Name?: string;
	Description?: string;
	SubText?: Array<string>;
	Extracharge?: Array<string>;
	Items?: Array<Item>;
	Columns?: Array<string>;
	Show?: boolean;
}

export interface MenuData {
	Title?: string;
	SubText?: Array<string>;
	Description?: string;
	Description2?: string;
	Extracharge?: Array<string>;
	Category?: Array<MenuCategory>;
	Show?: boolean;
}

export interface PageData {
	name?: string;
	path?: string;
	index?: number;
	data?: MenuData;
	Show?: boolean;
}

export interface Hours {
	dayName?: string;
	openTime?: string;
	closeTime?: string;
	isClosed?: boolean;
	Show?: boolean;
}

export interface TextContent {
	header?: string;
	text?: string;
	sign?: string;
	index?: number;
	Show?: boolean;
}

export interface CompanyData {
	phone?: string;
	email?: string;
	address1?: string;
	address2?: string;
	businessHours?: Array<Hours>;
	businessHoursMessage?: string;
	lunchHours?: Array<Hours>;
	lunchHoursMessage?: string;
	copyright?: string;
	priorityBanners?: Array<TextContent>;
	notices?: Array<TextContent>;
	promotions?: Array<TextContent>;
	Show?: boolean;
}

export interface MenuPagesResponse {
	MenuPage?: Array<MenuPage>;
}

export interface Category {
	Name?: string;
	Description?: string;
	Columns?: Array<string>;
	Items?: Array<Item>;
	Id?: number;
	Show?: boolean;
}

export interface MenuPage {
	Id?: number;
	Title?: string;
	ShowTitle?: boolean;
	Extracharge?: Array<string>;
	Items?: Array<Item>;
	Description?: string;
	Columns?: Array<string>;
	Category?: Array<Category>;
	Show?: boolean;
}

export interface StaffAssignment {
	staffId?: string;
	from?: string;
	to?: string;
	date?: Date;
	tableNumber?: string;
	key?: string;
	name?: string;
}

export interface Staff {
	staffId?: string;
	name?: string;
	active?: boolean;
	color?: string;
	key?: string;
}


var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

export function sortAlphaNum(a?: string, b?: string) {

	if(!a || !b) return 0;

	const aA = a.replace(reA, "");
	const bA = b.replace(reA, "");
	if (aA === bA) {
		const aN = parseInt(a.replace(reN, ""), 10);
		const bN = parseInt(b.replace(reN, ""), 10);
		return aN === bN ? 0 : aN > bN ? 1 : -1;
	} else {
		return aA > bA ? 1 : -1;
	}
}

export enum ActionMode {
	ADD,
	UPDATE
}

export const generateEmptyMenuPage = () => {
	return {
		Title: '',
		ShowTitle: true,
		Description: ''
	} as MenuPage;
}


export function transformFirebaseListToArray<T = any, U = any>(list: T, includeKey = false): U[] {
	const f: Array<U> = [];

	for(let prop in list) {
		f.push({
			...list[prop],
			key: includeKey === true ? prop : undefined
		} as any);
	}

	return f;
}
