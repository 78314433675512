import { MenuPage, MenuPagesResponse, StaffAssignment } from './models';

const api_url = "https://api.genjinorthyork.com/";
// const api_url = "http://localhost:2020/";

export const getData = async (): Promise<MenuPagesResponse> => {
	const res = await fetch(`${api_url}menupages`, {
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});

	return res.json(); // promise
};


export const saveData = async (menupages: Array<MenuPage>): Promise<any> => {
	const res = await fetch(`${api_url}menupages`, {
		method: "POST",
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			MenuPage: menupages,
		}),
	});

	return res.json(); // promise
};

export const getTables = async (): Promise<any> => {
	const res = await fetch(
		`${api_url}diningTables`
	);

	return res.json(); // promise
};

export const getStaff = async (): Promise<any> => {
	const res = await fetch(`${api_url}staff`);

	return res.json(); // promise
};

export const getStaffTableAssignments = async (date): Promise<any> => {
	const res = await fetch(`${api_url}staffTableAssignments?` + new URLSearchParams({ date: date.getTime() }));

	return res.json(); // promise
};

export const getCustomerTables = async (): Promise<any> => {
	const res = await fetch(`${api_url}customerTables`);

	return res.json(); // promise
};


export const createAssignment = async (staffAssignment: StaffAssignment): Promise<any> => {
	const res = await fetch(`${api_url}staffTableAssignment`, {
		method: "POST",
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify(staffAssignment),
	});

	return res.json(); // promise
};

export const updateAssignment = async (
	staffAssignment: StaffAssignment,
	recordId: string
): Promise<any> => {
	const res = await fetch(`${api_url}staffTableAssignment`, {
		method: "PUT",
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({ recordId, staffAssignment }),
	});

	return res.json(); // promise
};

export const deleteAssignment = async (
	recordId: string
): Promise<any> => {
	const res = await fetch(`${api_url}staffTableAssignment/${recordId}`, {
			method: "DELETE",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		}
	);

	return res.json(); // promise
};
