import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UIConfig from "./features/UIConfig/UIConfig";
import Header from "./features/Header/Header";
import CompanyData from "./features/CompanyData/CompanyData";
import Menu from "./features/Menu/Menu";
import Dashboard from "./features/Dashboard/Dashboard";
import Tables from "./features/Tables/Tables";
import Customers from "./features/Customers/Customers";

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Header></Header>
				<div className="main-sections">
					<div className="page-title">{}</div>
					<Switch>
						<Route path="/" exact component={Dashboard} />
						<Route
							path="/companydata"
							exact
							component={CompanyData}
						/>
						<Route path="/uiconfig" exact component={UIConfig} />
						<Route path="/menu" exact component={Menu} />
						<Route path="/tables" exact component={Tables} />
						<Route path="/customers" exact component={Customers} />
					</Switch>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default App;
